<template>
  <section class="box-height" style="padding: 0 15px">
    <div
      style="background-color: #fff; border: 1px #e5e5e5 solid"
      class="scollDom"
      id="scollDom"
    >
      <el-form
        :model="filter"
        ref="filter"
        style="background-color: #fff"
        label-width="60px"
      >
        <el-row style="margin: 0 10px; padding: 15px 0 0">
          <div class="clearfix">
            <el-form-item
              label="时间："
              prop="time"
              class="fl"
              style="margin-bottom: 20px"
            >
              <el-select
                v-model="filter.timeType"
                placeholder="请选择"
                filterable
                @change="loadInfo"
                class="dinput"
                style="width: 80px"
              >
                <el-option
                  v-for="(item, index) in timeTypeList"
                  :key="'time_' + index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-model="filter.time"
                v-if="filter.timeType !== '按月'"
                type="daterange"
                range-separator="至"
                @change="
                  needLastDay = false;
                  loadInfo();
                "
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                class="dinputr"
                style="width: 232px; padding: 0px 10px"
              ></el-date-picker>
              <el-date-picker
                v-if="filter.timeType == '按月'"
                v-model="filter.time"
                type="monthrange"
                range-separator="至"
                @change="
                  needLastDay = true;
                  loadInfo();
                "
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                class="dinputr"
                style="width: 232px; padding: 2px 10px"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              class="fl"
              label="角色："
              prop="roles"
              label-width="60px"
              style="margin-bottom: 20px; margin-left: 20px"
            >
              <el-select
                clearable
                filterable
                v-model="filter.roles"
                @change="loadInfo"
                multiple
                collapse-tags
                placeholder="请选择"
                style="min-width: 200px"
              >
                <el-option
                  v-for="(item, index) in rolesOption"
                  :label="item.name"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="searchIdList"
              label="对象："
              class="fl"
              style="margin-bottom: 20px; margin-left: 20px"
            >
              <el-select
                v-model="filter.searchType"
                placeholder="请选择大类"
                filterable
                @change="changeOption"
                style="width: 90px"
                class="dinput"
              >
                <el-option
                  v-for="item in optionsList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <el-cascader
                class="searchCascader dinputr"
                style="min-width: 240px; height: 35px"
                filterable
                clearable
                :options="options2"
                collapse-tags
                :show-all-levels="false"
                v-model="searchIdList"
                :props="props"
                placeholder="请选择小类"
                ref="dataRef"
                @change="getSearch"
                :filter-method="filterFun"
              >
              </el-cascader>
              <el-checkbox
                class="showCheckbox"
                style="width: 100px; margin-left: 15px"
                @change="showAccount"
                v-model="filter.show"
                >停用账户</el-checkbox
              >
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-button
              style="margin-right: 10px"
              size="mini"
              @click="exportKpi"
              :loading="exportBtnLoading"
              v-if="isExportBtnShow"
              >导出</el-button
            >
            <el-popover
              placement="bottom-start"
              title=""
              width="200"
              trigger="click"
              popper-class="configer2"
              v-model="isOpen"
            >
              <div class="select_check">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in dataList"
                    :key="index"
                    :label="item.item"
                    :value="item.item"
                    >{{ item.item }}
                  </el-checkbox>
                </el-checkbox-group>
                <div class="kpiButton">
                  <el-button
                    size="mini"
                    style="margin-left: 60px"
                    type="primary"
                    :disabled="checkList.length == 0"
                    @click="confirm"
                    >应用
                  </el-button>
                </div>
              </div>
              <el-button slot="reference" size="mini" @click="open"
                ><i class="el-icon-s-tools"></i>
              </el-button>
            </el-popover>

            <el-pagination
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <el-row
        :gutter="24"
        class="pd40"
        id="scroll_bar"
        style="position: relative; padding-bottom: 10px"
      >
        <el-col :span="24">
          <div class="clearfix">
            <table
              class="
                el-table
                tabBorder
                el-table--fit el-table--enable-row-hover
              "
              cellpadding="0"
              style="
                border-top: none;
                overflow: hidden;
                width: calc(100% - 45px);
                max-width: 1595px;
                background-color: #f5f7fa;
              "
              cellspacing="0"
              v-show="isFixed"
              :class="{ is_fixed: isFixed }"
            >
              <thead id="fixedhead">
                <tr
                  class="tableTdTrH fixedLeft"
                  :class="{ hasShadow: hasScroll }"
                >
                  <th
                    v-for="(tableItem, itemIndex) of kpiHeardItems"
                    :key="itemIndex"
                    :style="{ width: itemIndex == 1 ? '200px' : '150px' }"
                    style="font-weight: bold"
                    scope="col"
                  >
                    {{ tableItem }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <table
            class="el-table tabBorder el-table--fit el-table--enable-row-hover"
            cellpadding="0"
            style="border-top: 0; background-color: #f5f7fa"
            cellspacing="0"
            v-loading="listLoading"
            id="scollTable"
          >
            <thead class="has-gutter">
              <tr
                class="tableTdTrH fixedLeft"
                :class="{ hasShadow: hasScroll }"
              >
                <th
                  v-for="(tableItem, itemIndex) of kpiHeardItems"
                  :key="itemIndex"
                  :style="{ width: itemIndex == 1 ? '200px' : '150px' }"
                  style="
                    font-weight: bold;
                    border-bottom: 1px solid rgb(229, 229, 229);
                    border-top: 1px solid rgb(229, 229, 229);
                    box-shadow: none;
                    height: 39px;
                  "
                  scope="col"
                >
                  {{ tableItem }}
                  <el-tooltip
                    v-if="tableItem == 'KPI分值'"
                    class="item"
                    effect="dark"
                    content="KPI分数=【人才备注*1】+【内部面试*6】+【简历推荐*8】+【客户面试_初*12】+【offer*100】+【F2F面试*10】+【拜访客户*10】+【项目BD*10】"
                    placement="bottom"
                  >
                    <i
                      class="iconfont icon icon-wenhao-xiangsu"
                      style="font-size: 14px"
                    ></i>
                  </el-tooltip>
                </th>
              </tr>
            </thead>
            <tbody v-if="ListData.length > 0">
              <tr
                v-for="(item, index) of ListData"
                :key="index"
                class="tableTyTrH fixedLeft"
                :class="{ hasShadow: hasScroll }"
              >
                <td
                  v-for="(tableItem, itemIndex) of item.kpiItems"
                  :key="itemIndex"
                  :style="{ width: itemIndex == 1 ? '200px' : '150px' }"
                >
                  <span
                    :class="
                      tableItem.sourceId && tableItem.value !== '0'
                        ? 'tabHref'
                        : ''
                    "
                    @click="
                      tableItem.sourceId && tableItem.value !== '0'
                        ? tableItem.item == null
                          ? getkpiDataThrough(tableItem)
                          : dialogVisibletrue(tableItem)
                        : ''
                    "
                    >{{ tableItem.value }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <p
                style="
                  text-align: center;
                  height: 50px;
                  line-height: 50px;
                  color: #999;
                "
              >
                暂无数据
              </p>
            </tbody>
          </table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
      <!--            弹出框-->
      <!--            "备注数量" || "内部面试数量"-->
      <el-drawer
        :visible.sync="dialogVisible"
        custom-class="drawerClass"
        direction="ttb"
        :modal="false"
        append-to-body
        :destroy-on-close="true"
      >
        <div slot="title" class="drawerTitle">
          {{ dialogTitle }}
        </div>
        <div class="drawerCon">
          <el-tabs
            v-if="
              dialogTitle == '候选人备注' ||
              dialogTitle == '内部面试' ||
              dialogTitle == '简历推荐' ||
              dialogTitle == '客户面试_初' ||
              dialogTitle == '项目BD' ||
              dialogTitle == 'F2F面试'
            "
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane
              :label="tabData[0].label + '   ' + tabData[0].count"
              name="first"
            ></el-tab-pane>
            <el-tab-pane
              :label="tabData[1].label + '   ' + tabData[1].count"
              name="second"
            ></el-tab-pane>
          </el-tabs>
          <el-button size="mini" @click="exportExl">导出</el-button>
          <el-pagination
            :hide-on-single-page="pagination1.total > 10 ? false : true"
            v-if="pagination1.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="pagination1.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination1.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination1.total"
          ></el-pagination>
          <table
            class="
              el-table
              tabBorder
              el-table--fit el-table--enable-row-hover
              digTable
              specialS
            "
            cellpadding="0"
            cellspacing="0"
            v-if="isRemark"
          >
            <thead class="has-gutter">
              <tr class="tableTdTrH">
                <th
                  v-for="(i, index) in headerOption"
                  :key="index"
                  :style="
                    i == '详情'
                      ? 'max-width: 20%'
                      : `max-width: ${80 / (headerOption.length - 1)}%`
                  "
                  scope="col"
                >
                  {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <p v-show="isData" style="text-align: center; color: #909399">
                暂无数据
              </p>
              <tr
                v-for="(i, index) in iscusoption1"
                :key="index"
                class="tableTyTrH"
              >
                <td
                  v-for="(ie, ins) in iscusoption1[index].rowData"
                  :key="ins"
                  :style="
                    ie.indexOf('commentColumnId') > 0
                      ? 'max-width: 20%'
                      : `max-width: ${80 / (headerOption.length - 1)}%`
                  "
                  valign="middle"
                >
                  <template v-if="ie.startsWith('[')">
                    <pre
                      style="
                        line-height: 20px;
                        height: calc(100% - 24px);
                        display: flex;
                        align-items: center;
                      "
                      :style="
                        ie.indexOf('commentColumnId') > 0
                          ? ''
                          : 'cursor: pointer;color: #2b80ff;'
                      "
                      v-html="serializeIE(ie)"
                      @click="goNav(ie)"
                    ></pre>
                  </template>
                  <template v-else>
                    <span
                      v-html="internalM(ie, ins)"
                      @click="nativalNav(ie, ins)"
                      style="
                        display: block;
                        height: 100%;
                        align-items: center;
                        overflow: hidden;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    ></span>
                  </template>
                </td>
                <td
                  v-if="detailData.qualityCheck"
                  :style="`max-width: ${80 / (headerOption.length - 1)}%`"
                >
                  <span
                    v-if="havePermissions"
                    class="tabHref"
                    @click="changeStatus(i)"
                    >{{
                      activeName == "first" ? "标记无效" : "恢复为有效"
                    }}</span
                  >
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            class="
              el-table
              tabBorder
              el-table--fit el-table--enable-row-hover
              digTable
            "
            cellpadding="0"
            cellspacing="0"
            v-else
          >
            <thead class="has-gutter">
              <tr class="tableTdTrH">
                <th
                  v-for="(i, index) in headerOption"
                  :key="index"
                  style="width: 120px"
                  scope="col"
                >
                  {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <p v-show="isData" style="text-align: center; color: #909399">
                暂无数据
              </p>
              <tr
                v-for="(i, index) in iscusoption1"
                :key="index"
                class="tableTyTrH"
              >
                <td
                  v-for="(ie, ins) in iscusoption1[index].rowData"
                  :key="ins"
                  style="width: 120px"
                  @click="goUrl(ie, ins)"
                >
                  <span v-html="renderIE(ie)"></span>
                </td>
                <td
                  v-if="detailData.qualityCheck"
                  style="max-width: ${80 / (headerOption.length - 1)}%"
                >
                  <span
                    v-if="havePermissions"
                    class="tabHref"
                    @click="changeStatus(i)"
                    >{{
                      activeName == "first" ? "标记无效" : "恢复为有效"
                    }}</span
                  >
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </table>
          <el-pagination
            :hide-on-single-page="pagination1.total > 10 ? false : true"
            v-if="pagination1.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="pagination1.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination1.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination1.total"
          ></el-pagination>
          <el-dialog
            :title="activeName == 'first' ? '标记无效' : '提示'"
            width="600px"
            :visible.sync="diaDetailVisible"
            :destroy-on-close="true"
            append-to-body
            :before-close="closeMarker"
          >
            <div v-if="activeName == 'first'">
              <p style="margin: 0px 15px 10px">标记无效后将不统计KPI</p>
              <el-form
                @submit.native.prevent
                label-width="100px"
                :model="form"
                ref="form"
              >
                <el-form-item
                  label="原因描述："
                  prop="comment"
                  :rules="{
                    required: true,
                    message: '请输入原因描述',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    type="textarea"
                    maxlength="200"
                    :rows="3"
                    placeholder="请输入原因描述"
                    v-model.trim="form.comment"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div v-else>
              <p style="font-size: 15px; font-weight: bold; margin: 0px 15px">
                确定恢复为有效备注？
              </p>
              <p style="margin: 10px 15px">恢复后将被统计KPI</p>
            </div>
            <span slot="footer">
              <el-button size="mini" @click="diaDetailVisible = false"
                >取 消</el-button
              >
              <el-button size="mini" type="primary" @click="saveDiaDetail"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </el-drawer>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="drawerTitle == '客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="drawerTitle == '人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ drawerTitle }}
      </div>
      <div class="drawerCon" :style="drawerTitle == '人才' ? 'padding:0' : ''">
        <addoreEditCus
          v-if="drawerTitle == '编辑客户' && drawerVisible"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
        <customerInfo
          v-if="drawerTitle == '客户' && drawerVisible"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
        <resumeDetails
          v-if="drawerTitle == '人才' && drawerVisible"
          :resumeDetailId="customId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  getKpiPlanList,
  addorEdit,
  myKpiDetailList,
  kpiItemDetail,
  getRoleBaseInfos,
  permissionTree, //小类
  permissionType, // 大类
  exportKpiDetailList,
  exportKpiDetail,
  kpiDataThrough, //KPI数透
  exportKpiDataThrough, //导出KPI数透
  qualityCheckMarkAsinValid, //标记为无效
  qualityCheckrecoverAsinValid, //恢复为有效
  detailQualifiedCount, //kpitab数量
} from "../../api/api";
import { ApiBaseUrl } from "../../api/http";
import customerInfo from "./../../components/customer/customerInfo";
import addoreEditCus from "./../../components/customer/addorEditCustomer";
import resumeDetails from "../../components/resumeDetail";
export default {
  name: "Kpi",
  components: { customerInfo, addoreEditCus, resumeDetails },
  data() {
    return {
      drawerVisible: false,
      drawerTitle: "",
      customId: null,
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      activedToInfo: "1",
      userId: parseInt(this.getStore("uid")),
      filter: {
        //查询条件
        time: [
          new Date().Format("yyyy-MM-dd"),
          new Date().Format("yyyy-MM-dd"),
        ],
        roles: "",
        timeType: "总计",
        searchType: "顾问",
        searchIdList: [],
        show: false, // 默认不展示停用数据
      },
      exportBtnLoading: false,
      kpiPlanInput: {},
      isExportBtnShow: false,
      baseUrl: ApiBaseUrl,
      clickGetName: "",
      tableTitle: "",
      getTypeVal: null,
      sourceidN: null,
      dialogTitle: "",
      BD: false,
      isClass: false,
      isRemark: false,
      alink: false,
      isData: false,
      optionsList: [],
      timeTypeList: ["按月", "按周", "按日", "总计"],
      exportKpiType: {}, //kpi详情导出
      searchIdList: [],
      sourceIds: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      options2: [],
      headerOption: [],
      kpiHeardItems: [],
      iscusoption1: [],
      BDCALL: false,
      currentOptions: [],
      iscusoption: [],
      offeroption: [],
      checkList: [],
      rolesOption: [],
      dataList: [],
      listVal: [],
      options: [],
      gethas: [],
      kpiList: [],
      ListData: [],
      listLoading: false,
      dialogVisible: false,
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      pagination1: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      isOpen: false,
      offer: false,
      isnumber: false,
      iscus: false,
      secondVal: "",
      isFixed: false,
      hasScroll: false,
      kpiData: {},
      lastDay: "",
      needLastDay: false,
      detailData: {},
      activeName: "first",
      diaDetailVisible: false,
      form: {
        id: "",
        comment: "",
        kpiItem: "",
      },
      tabData: [],
      havePermissions: false,
    };
  },
  destroyed() {
    this.canScroll();
  },
  watch: {
    drawerVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  created() {
    let permTypes = this.getStore("permiss");
    this.permissionType();
    // 下拉框里的值
    this.dataList = [
      {
        item: "候选人备注",
        isUse: 0,
        id: 0,
      },
      {
        item: "coldcall备注",
        isUse: 0,
        id: 0,
      },
      {
        item: "加入项目",
        isUse: 0,
        id: 0,
      },
      {
        item: "内部面试",
        isUse: 0,
        id: 0,
      },
      {
        item: "简历推荐",
        isUse: 0,
        id: 0,
      },
      {
        item: "客户面试_初",
        isUse: 0,
        id: 0,
      },
      {
        item: "客户面试_2",
        isUse: 0,
        id: 0,
      },
      {
        item: "客户面试_3及以上",
        isUse: 0,
        id: 0,
      },
      {
        item: "客户面试_终",
        isUse: 0,
        id: 0,
      },
      {
        item: "客户面试_全部",
        isUse: 0,
        id: 0,
      },
      {
        item: "Offer",
        isUse: 0,
        id: 0,
      },
      {
        item: "失败Offer",
        isUse: 0,
        id: 0,
      },
      {
        item: "项目BD",
        isUse: 0,
        id: 0,
      },
      {
        item: "F2F面试",
        isUse: 0,
        id: 0,
      },
      {
        item: "入职",
        isUse: 0,
        id: 0,
      },
      {
        item: "拜访客户",
        isUse: 0,
        id: 0,
      },
      {
        item: "人才_新增",
        isUse: 0,
        id: 0,
      },
      {
        item: "人才_插件导入",
        isUse: 0,
        id: 0,
      },
      {
        item: "人才_上传",
        isUse: 0,
        id: 0,
      },
      {
        item: "人才_上传List",
        isUse: 0,
        id: 0,
      },
      {
        item: "人才_全部",
        isUse: 0,
        id: 0,
      },
      {
        item: "BD线索",
        isUse: 0,
        id: 0,
      },
      {
        item: "BD主导",
        isUse: 0,
        id: 0,
      },
      {
        item: "客户沟通",
        isUse: 0,
        id: 0,
      },
    ];
    if (permTypes.includes("record:KpiData:qualityCheckMark")) {
      this.havePermissions = true;
    }
    this.permissionTree("顾问", true);
    this.loadListRole();
    this.sourceIds.push(this.userId);
    this.myKpiDetailList();
  },
  methods: {
    loadList() {
      if (this.drawerTitle == "编辑客户") {
        this.drawerTitle = "客户";
      }
    },
    detailsPop(id) {
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = id; //客户id
      this.activedToInfo = "1";
    },
    toEditComInfo(id) {
      this.drawerTitle = "编辑客户";
      this.customId = id;
    },
    // 查询维度
    // 大类
    permissionType() {
      permissionType().then((res) => {
        if (res.success) {
          this.optionsList = res.result;
        }
      });
    },
    // 小类
    permissionTree(val, isfirst) {
      permissionTree({ type: val, showDisableUser: this.filter.show }).then(
        (res) => {
          if (res.success) {
            this.options2 = res.result;
            // 如果是初次加载页面数据，在options2中查找userId对应的searchIdList
            if (isfirst) {
              this.searchIdList = [];
              let list = this.changeDetSelect(this.userId, this.options2);
              this.searchIdList.push(list);
            }
          }
        }
      );
    },
    //回显(多级)
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (
              childrenData[j].children &&
              childrenData[j].children.length > 0
            ) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }

      return childrenEach(treeData, depth);
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      this.sourceIds = this.searchIdList.map((i) => {
        return i[i.length - 1];
      });
      this.loadInfo();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.myKpiDetailList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.myKpiDetailList();
    },
    handleSizeChange1(val) {
      //pageSize 改变时会触发
      this.pagination1.pageSize = val;
      this.pagination1.pageNo = 1;
      this.kpiItemDetail();
    },
    handleCurrentChange1(val) {
      //currentPage 改变时会触发
      this.pagination1.pageNo = val;
      this.kpiItemDetail();
    },
    // 弹框
    dialogVisibletrue(val) {
      this.activeName = "first";
      this.clickGetName = val.item;
      this.dialogTitle = val.item;
      this.pagination1.pageNo = 1;
      this.listVal = val;
      this.kpiItemDetail().then(() => {
        if (
          val.item == "候选人备注" ||
          val.item == "coldcall备注" ||
          val.item == "内部面试" ||
          val.item == "F2F面试" ||
          val.item == "客户沟通"
        ) {
          this.isRemark = true;
        } else {
          this.isRemark = false;
        }
      });

      this.dialogVisible = true;
      if (val.item == "Offer") {
        this.offer = true;
      } else if (val.item == "候选人备注") {
        this.iscus = true;
      } else if (val.item == "内部面试") {
        this.iscus = true;
      }
    },
    // 角色
    loadListRole() {
      getRoleBaseInfos().then((res) => {
        if (res.success) {
          this.rolesOption = res.result;
        }
      });
    },
    //打开
    open() {
      this.getKpiPlanList();
    },
    // 应用
    confirm() {
      this.isOpen = false;
      const addOrEditKpiPlanDto = {};
      const dataKpiItems = [];
      this.kpiHeardItems = [];
      for (let i = 0; i < this.dataList.length; i++) {
        for (let j = 0; j < this.checkList.length; j++) {
          if (this.checkList[j]) {
            if (this.dataList[i].item == this.checkList[j]) {
              dataKpiItems.push({ isUse: 1, item: this.dataList[i].item });
              this.kpiHeardItems = dataKpiItems;
            }
          } else {
            return;
          }
        }
      }
      // return;
      if (this.checkList.length > 0) {
        addOrEditKpiPlanDto.dataKpiItems = dataKpiItems;
        addorEdit(addOrEditKpiPlanDto).then((res) => {
          if (res.success) {
            this.myKpiDetailList();
          }
        });
      }
      // debugger
      if (!this.filter.searchType || !this.searchIdList) {
        this.$message.error("考核维度必填，请选择考核维度！");
        return false;
      }
    },
    changeOption(val) {
      //
      this.searchIdList = "";
      this.sourceIds = [];
      this.pagination.pageNo = 1;
      if (val) {
        this.permissionTree(val);
      }
    },
    // 查询kpi考核项
    getKpiPlanList() {
      getKpiPlanList().then((res) => {
        if (res.success) {
          if (res.result) {
            this.checkList = [];
            res.result.forEach((item) => {
              this.dataList.forEach((i) => {
                if (item.item == i.item) {
                  this.checkList.push(i.item);
                }
              });
            });
          }
        }
      });
    },
    exportExl() {
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      //导出
      exportKpiDetail(this.exportKpiType).then((res) => {
        if (res.success) {
          if (!setData) {
            isconfirm = false;
            location.href = this.baseUrl + res.result;
          }
        }
      });
    },
    // 弹出框数据
    kpiItemDetail() {
      $(".diaDiv").scrollTop(0);
      return new Promise((resolve) => {
        const obj = {};
        obj.startDate = this.listVal.startDate;
        obj.endDate = this.listVal.endDate;
        obj.sourceId = this.listVal.sourceId;
        obj.item = this.listVal.item;
        obj.current = this.pagination1.pageNo;
        obj.size = this.pagination1.pageSize;
        obj.type = this.listVal.sourceType;
        obj.qualified = this.activeName == "first" ? true : false;
        this.exportKpiType = obj;
        kpiItemDetail(obj).then((res) => {
          if (res.success) {
            this.detailData = res.result;
            if (res.result.qualityCheck) {
              this.getQualifiedCount();
            }
            this.headerOption = res.result.headers;
            this.pagination1.total = res.result.total;
            this.pagination1.pageSize = res.result.size;
            this.iscusoption1 = res.result.data;

            if (this.iscusoption1.length < 1) {
              //暂无数据
              this.isData = true;
            } else {
              this.isData = false;
            }
          }
          resolve();
        });
      });
    },
    getQualifiedCount() {
      let obj = {};
      obj.startDate = this.listVal.startDate;
      obj.endDate = this.listVal.endDate;
      obj.sourceId = this.listVal.sourceId;
      obj.item = this.listVal.item;
      obj.type = this.listVal.sourceType;
      detailQualifiedCount(obj).then((res) => {
        if (res.success) {
          this.tabData = res.result;
        }
      });
    },
    showAccount() {
      this.permissionTree(this.filter.searchType);
      this.loadInfo();
    },
    // 查询
    loadInfo() {
      if (!this.filter.searchType || this.sourceIds.length == 0) {
        this.$message.error("考核维度必填，请选择考核维度！");
        this.ListData = [];
        return false;
      }
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 50;
      this.myKpiDetailList();
    },
    myKpiDetailList() {
      $("#scollDom").scrollTop(0);
      this.listLoading = true;
      const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
      const time = `${yesterday.getFullYear()}-${(
        "" +
        (yesterday.getMonth() + 1)
      ).padStart(2, 0)}-${("" + yesterday.getDate()).padStart(2, 0)}`;
      if (
        this.filter.timeType !== "按月" &&
        this.filter.time &&
        this.needLastDay
      ) {
        let dataYM = this.filter.time[1].split("-");
        this.lastDayT = new Date(dataYM[0], dataYM[1], 0).Format("yyyy-MM-dd");
        this.filter.time[1] = this.lastDayT;
      }
      this.kpiPlanInput.startDate = this.filter.time
        ? this.filter.time[0]
        : time;
      this.kpiPlanInput.endDate = this.filter.time ? this.filter.time[1] : time;
      const page = (this.kpiPlanInput.page = {});
      page.size = this.pagination.pageSize;
      page.current = this.pagination.pageNo;
      this.kpiPlanInput.roleId = this.filter.roles ? this.filter.roles : [];
      this.kpiPlanInput.timeType = this.filter.timeType;
      // filter.searchIdList 小类
      this.kpiPlanInput.show = true;
      if (this.filter.searchType == "顾问") {
        this.kpiPlanInput.show = !this.filter.show;
        this.kpiPlanInput.sourceIds = this.sourceIds;
      } else {
        if (this.filter.searchIdList) {
          this.kpiPlanInput.sourceIds = this.sourceIds;
        } else {
          this.kpiPlanInput.sourceIds = [];
        }
      }
      this.kpiPlanInput.type = this.filter.searchType
        ? this.filter.searchType
        : this.optionsList[0].type;

      myKpiDetailList(this.kpiPlanInput).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.kpiData = {};
          if (res.result) {
            this.ListData = res.result.data;
            this.kpiHeardItems = res.result.header;
            this.pagination.pageSize = res.result.size;
            this.pagination.total = res.result.total;
            if (this.ListData) {
              this.ListData.forEach((item) => {
                if (!item.kpiItems) {
                  this.isExportBtnShow = false;
                } else {
                  this.isExportBtnShow = true;
                }
              });
            } else {
              this.isExportBtnShow = false;
            }
          } else {
            this.$message.info(res.message);
          }
        }
        this.listLoading = false;
      });
    },
    goNav(router) {
      const arr = JSON.parse(router);
      if (Array.isArray(arr)) {
        if (!router.includes("commentColumnId")) {
          return arr.forEach((a) => {
            let _router = this.$router.resolve({
              path: "/resume/details",
              query: { id: a.relativeId },
            });
            window.open(_router.href, "_blank");
          });
        }
      }
    },
    goUrl(url, index) {
      try {
        if (
          [
            "简历推荐",
            "加入项目",
            "内部面试",
            "客户面试_全部",
            "客户面试_初",
            "客户面试_2",
            "客户面试_3及以上",
            "客户面试_终",
            "Offer",
            "失败Offer",
            "项目BD",
            "F2F面试",
            "拜访客户",
            "入职",
            "人才_新增",
            "人才_插件导入",
            "人才_上传",
            "人才_上传List",
            "人才_全部",
            "BD线索",
            "BD主导",
          ].includes(this.clickGetName)
        ) {
          const parseUrl = JSON.parse(url);
          const headerText = this.headerOption[index];
          if (headerText === "候选人") {
            this.drawerTitle = "人才";
            this.drawerVisible = true;
            this.customId = parseUrl.relativeId;
          } else if (headerText === "项目名称") {
            let _router = this.$router.resolve({
              path: "/project/details",
              query: { id: parseUrl.relativeId },
            });
            window.open(_router.href, "_blank");
          } else if (headerText === "客户名称") {
            this.drawerTitle = "客户";
            this.drawerVisible = true;
            this.customId = parseUrl.relativeId;
          }
        }
      } catch (e) {
        return;
      }
    },

    /**
     * 提取json中name字段 不是json返回原值
     * @example renderIE('2020-02-03 15:52:20'); // => 2020-02-03 15:52:20
     * @example renderIE('{"name": "移动测试-移动测试","relativeId": "12"}'); // => 移动测试-移动测试
     * @param ie {string}
     * @returns {string}
     */
    renderIE(ie) {
      if (ie) {
        let _ie;
        try {
          _ie = JSON.parse(ie);
        } catch (e) {
          return ie;
        }
        if (/^\d+$/.test(_ie)) {
          return _ie;
        }
        if (_ie.name) {
          return `<a class="aStyle">${_ie.name}</a>`;
        }
      }
    },
    handleClose(done) {
      done();
    },
    nativalNav(i, index) {
      const headerText = this.headerOption[index];
      // debugger
      try {
        let _da = JSON.parse(i);
        if (headerText === "候选人") {
          this.drawerTitle = "人才";
          this.drawerVisible = true;
          this.customId = _da.relativeId;
        } else if (headerText === "项目名称") {
          let _router = this.$router.resolve({
            path: "/project/details",
            query: { id: _da.relativeId },
          });
          window.open(_router.href, "_blank");
        } else if (headerText === "客户名称") {
          this.drawerTitle = "客户";
          this.drawerVisible = true;
          this.customId = _da.relativeId;
        }
      } catch (e) {
        return;
      }
    },
    internalM(i, index) {
      let _ie;
      try {
        _ie = JSON.parse(i);
      } catch (e) {
        return i;
      }
      if (Array.isArray(_ie)) {
        return;
      }
      if (_ie.name) {
        return `<a class="aStyle">${_ie.name}</a>`;
      } else {
        return _ie;
      }
    },
    serializeIE(ie) {
      //备注详情字段处理
      try {
        const arr = JSON.parse(ie);
        let str = [];
        if (Array.isArray(arr)) {
          if (ie.includes("commentColumnId")) {
            str = arr.filter((a) => {
              return a.value;
            });
            return str
              .map(
                (a) =>
                  `${a.columnTitle == "详情" ? "" : a.columnTitle + "："}${
                    a.value
                  }`
              )
              .join("\n");
          } else {
            return arr.map((a) => `${a.name}`).join("\n");
          }
        } else {
          // 不是数组就走catch
          throw 1;
        }
      } catch (e) {
        return ie;
      }
    },
    exportKpi() {
      if (this.kpiPlanInput && this.kpiPlanInput.startDate) {
        var exportKpiPlanInput = this.kpiPlanInput;
        exportKpiPlanInput.page.current = 1;
        exportKpiPlanInput.page.size = this.pagination.total;
      } else {
        var exportKpiPlanInput = {
          startDate: this.ListData[0].kpiItems[0].startDate,
          endDate: this.ListData[0].kpiItems[0].endDate,
          page: { size: this.pagination.total, current: 1 },
          roleId: [],
          sourceIds: [],
          show: this.filter.show,
          type: this.filter.searchType
            ? this.filter.searchType
            : this.optionsList[0].type,
        };
        var arr = [];
        this.ListData.forEach((item) => {
          arr.push(item.kpiItems[0].sourceId);
        });
        exportKpiPlanInput.sourceIds = Array.from(new Set(arr));
      }
      this.exportBtnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      if (this.kpiData.sourceId) {
        exportKpiDataThrough(this.kpiData).then((res) => {
          this.exportBtnLoading = false;
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
        });
      } else {
        exportKpiDetailList(exportKpiPlanInput).then((res) => {
          this.exportBtnLoading = false;
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
        });
      }
    },
    getkpiDataThrough(row) {
      this.listLoading = true;
      let data = {
        endDate: row.endDate,
        sourceId: row.sourceId,
        startDate: row.startDate,
        show: this.filter.show,
      };
      this.kpiData = data;
      kpiDataThrough(data).then((res) => {
        this.exportBtnLoading = false;
        if (res.success) {
          this.listLoading = false;
          if (res.result) {
            this.ListData = res.result.data;
            this.kpiHeardItems = res.result.header;
            if (this.ListData) {
              this.ListData.forEach((item) => {
                if (!item.kpiItems) {
                  this.isExportBtnShow = false;
                } else {
                  this.isExportBtnShow = true;
                }
              });
            } else {
              this.isExportBtnShow = false;
            }
          } else {
            this.$message.info(res.message);
          }
        }
        this.listLoading = false;
      });
    },
    handleScroll() {
      let dom = document.querySelector("#scollDom");
      let scrollTop = dom.pageYOffset || dom.scrollTop; // 滚动条偏移量
      let offsetTop = document.querySelector("#scroll_bar").offsetTop; // 要滚动到顶部吸附的元素的偏移量
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    },
    handleToFixed() {
      let scrollLeft = $("#scollTable").scrollLeft();
      var margin_left = -Number(scrollLeft);
      $("#fixedhead").css({ "margin-left": margin_left });
      if (scrollLeft) {
        this.hasScroll = true;
      } else {
        this.hasScroll = false;
      }
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    handleClick(val) {
      this.pagination1.pageSize = 50;
      this.pagination1.pageNo = 1;
      this.kpiItemDetail();
    },
    //详情表格操作
    changeStatus(data) {
      this.diaDetailVisible = true;
      this.form.id = data.id;
      this.form.kpiItem = this.dialogTitle;
    },
    //详情表格操作确定
    saveDiaDetail() {
      if (this.activeName == "first") {
        this.$refs.form.validate((valid) => {
          if (valid) {
            qualityCheckMarkAsinValid(this.form).then((res) => {
              if (res.success) {
                this.closeMarker();
                this.kpiItemDetail();
              }
            });
          }
        });
      } else {
        qualityCheckrecoverAsinValid(this.form).then((res) => {
          if (res.success) {
            this.closeMarker();
            this.kpiItemDetail();
          }
        });
      }
    },
    closeMarker() {
      this.diaDetailVisible = false;
      this.form.comment = "";
    },
  },
  mounted() {
    const _this = this;
    _this.noScroll(); //禁止滚动
    document
      .querySelector("#scollDom")
      .addEventListener("scroll", _this.handleScroll);
    document
      .querySelector("#scollTable")
      .addEventListener("scroll", _this.handleToFixed);
    document.onkeydown = function (e) {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.pagination.pageNo = 1;
        _this.pagination.pageSize = 50;
        _this.loadInfo();
      }
    };
  },
};
</script>

<style scoped lang="scss">
.fl :deep(.el-form-item__content) {
    display: flex;
    align-items: center;
  }
.tableTdTrH {
  line-height: 36px;
  height: 36px;
  font-weight: bold;
  background: #f5f7fa;
}
.tableTyTrH {
  height: auto;
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    background-color: #fff;
  }
}
.fixedLeft {
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    position: sticky;
    left: 0; /* 首行永远固定在左侧 */
    z-index: 4;
  }
}
.hasShadow {
  td:nth-child(2),
  th:nth-child(2) {
    left: 120px;
    box-shadow: 5px 0 10px -5px #ddd;
  }
}
.is_fixed {
  position: fixed;
  top: 44px;
  z-index: 9;
}
.kpiSet {
  height: 28px;
  /*padding: 10px 15px;*/
  border: 1px solid #ededed;
  width: 85px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  /* margin-left: 15px; */
  display: block;
  float: left;
  color: #606266;
  i {
    /*color: rgb(187, 187, 187);*/
    font-size: 14px;
  }

  &:hover {
    color: #333333;
    border: 1px solid #999999 !important;
  }
}

pre {
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
  white-space: pre-line;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
thead {
  // border-top: 1px solid #ebeef5;
  // border-bottom: 1px solid #ebeef5;
}
thead,
tbody {
  display: block;
}

th {
  padding: 0;
  /*flex: 1;*/
  background-color: rgb(245, 247, 250);
  font-weight: normal;
}

tr {
  height: 50px;
  line-height: 50px;
  /*display: flex;*/
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
}

td,
th {
  display: inline-block;
  width: 150px;
  /*flex: 1;*/
  padding: 0 15px;
}

.digTable tr {
  display: flex;
}

.digTable th,
.digTable td {
  flex: 1;
  /*width: 250px !important;*/
}

.iconshaixuan:hover {
  color: #ff3f3f;
  cursor: pointer;
}

.specialS td {
  // overflow: auto;
  white-space: normal;
}
</style>
<style lang="less">
.aStyle {
  cursor: pointer;
  color: #2b80ff;

  &:hover {
    text-decoration: underline;
  }
}

.select_check {
  .el-checkbox {
    display: block;
    margin-bottom: 10px;
  }
  .el-checkbox-group {
    height: 520px;
    overflow-y: auto;
    padding: 10px 15px 0 15px;
  }
}

.diaDiv .el-dialog {
  padding-bottom: 60px;
}

.diaDiv .el-dialog__body {
  border-bottom: none;
}
.showCheckbox {
  .el-checkbox__label {
    color: #606266 !important;
  }
}
</style>
<style lang="scss">
.el-cascader .el-input .el-input__inner {
  height: 32px !important;
}
// .configer2 {
//   padding: 0 !important;
//   .el-checkbox__input.is-checked + .el-checkbox__label {
//     color: #606266;
//   }
//   .select_check {
//     background: white;
//     height: 575px;
//     position: relative;
//     .kpiButton {
//       width: 100%;
//       position: absolute;
//       bottom: 0;
//       right: 10px;
//       padding: 10px;
//       text-align: right;
//       border-top: 1px solid #ededed;
//       background: #f0f0f0;
//     }
//   }
// }
.configer2 {
  padding: 0 !important;
  .el-checkbox {
    display: block;
    margin-bottom: 10px;
    // line-height: 22px;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .select_check {
    background: white;
    height: 380px;
    position: relative;
    .kpiButton {
      // width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      text-align: right;
      border-top: 1px solid #ededed;
      background: #fff;
    }
  }
}
.dinput {
  .el-input--suffix .el-input__inner {
    border-radius: 5px 0px 0px 5px;
  }
}
.dinputr {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  .el-input--suffix .el-input__inner {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}
</style>

<style lang="scss">
.select_check {
  .el-checkbox {
    display: block;
    margin-bottom: 10px;
  }
  .el-checkbox-group {
    height: 320px;
    overflow-y: auto;
    padding: 10px 15px 0 15px;
  }
}
</style>